import { FieldAppSDK } from '@contentful/app-sdk';
import { ASSET_META, ASSET_TYPE, CONTENT_PATH, FALLBACK_THUMBNAIL, IMAGE_PATH } from './constants';
import { DocumentProps, ImageProps, VideoProps } from './types';

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const formatAssetMetaData = (metadata: Promise<unknown>): ImageProps | VideoProps | DocumentProps | void => {
  if (!metadata[ASSET_META.DOMAIN] || !metadata[ASSET_META.FILE]) {
    console.error('The asset does not have a dynamic path');
    return;
  }

  if (metadata[ASSET_META.TYPE] === ASSET_TYPE.VIDEO) {
    return getVideoDetails(metadata);
  }

  if (metadata[ASSET_META.TYPE] === ASSET_TYPE.IMAGE) {
    return getImageDetails(metadata);
  }

  return getDocumentDetails(metadata);
};

const getImageDetails = (metadata: Promise<unknown>): ImageProps => {
  return {
    id: metadata[ASSET_META.ID],
    uuid: metadata[ASSET_META.UUID],
    url: getEncodedURI(metadata[ASSET_META.DOMAIN] + IMAGE_PATH + metadata[ASSET_META.FILE]),
    height: metadata[ASSET_META.IMAGE_LENGTH],
    width: metadata[ASSET_META.IMAGE_WIDTH],
    title: metadata[ASSET_META.TITLE],
    description: metadata[ASSET_META.DESCRIPTION] || metadata[ASSET_META.TITLE],
    type: metadata[ASSET_META.TYPE],
  };
};

const getDocumentDetails = (metadata: Promise<unknown>): DocumentProps => {
  return {
    id: metadata[ASSET_META.ID],
    uuid: metadata[ASSET_META.UUID],
    url: getEncodedURI(metadata[ASSET_META.DOMAIN] + CONTENT_PATH + metadata[ASSET_META.FILE]),
    thumbnail: metadata[ASSET_META.DOC_THUMBNAIL]
      ? getEncodedURI(metadata[ASSET_META.DOMAIN] + IMAGE_PATH + metadata[ASSET_META.DOC_THUMBNAIL])
      : FALLBACK_THUMBNAIL.DOCUMENT,
    title: metadata[ASSET_META.TITLE],
    size: `${bytesToMegaBytes(metadata[ASSET_META.SIZE])} MB`,
    type: metadata[ASSET_META.TYPE],
    format: metadata[ASSET_META.FORMAT],
  };
};

const getVideoDetails = (metadata: Promise<unknown>): VideoProps => {
  return {
    id: metadata[ASSET_META.ID],
    uuid: metadata[ASSET_META.UUID],
    url: getEncodedURI(metadata[ASSET_META.DOMAIN] + CONTENT_PATH + metadata[ASSET_META.FILE]),
    thumbnail: metadata[ASSET_META.FILE]
      ? getEncodedURI(metadata[ASSET_META.DOMAIN] + IMAGE_PATH + metadata[ASSET_META.FILE])
      : FALLBACK_THUMBNAIL.VIDEO,
    title: metadata['dc:title'],
    format: metadata['dc:format'],
    type: metadata['dam:scene7Type'],
  };
};

const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

const getEncodedURI = (url: string) => {
  return decodeURIComponent(url) === url ? encodeURI(url) : url;
};

// null instances should be replaced with empty object. For more reference see https://knaufjira.atlassian.net/browse/KUCMS-2895
export const resolveNullContentfulField = async (sdk: FieldAppSDK) => {
  const sdkFieldValue = sdk.field?.getValue();
  if (sdkFieldValue?.[0] !== undefined) {
    const sdkFieldFormatted: [] = await sdkFieldValue?.map((item) => (item ? item : {}));
    sdk.field.setValue(sdkFieldFormatted);
  }
};
