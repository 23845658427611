import React, { useEffect, useState } from 'react';
import { Asset as DamAsset } from '@contentful/dam-app-base';
import { Asset, Box, CopyButton, FormControl, TextInput } from '@contentful/f36-components';
import { Note } from '@contentful/f36-note';
import tokens from '@contentful/f36-tokens';
import { APP_PARAMETER } from 'src/Utils/constants';
import { debounce } from '../../Utils/utils';
import { MediaDescriptionProps } from '.';

const MediaDescription: React.FC<MediaDescriptionProps> = ({ onDescriptionChange, sdk }) => {
  const [damAssets, setDamAssets] = useState([]);
  const DEBOUNCE_TIME = 500;
  const FILED_RESIZE_DELAY = 500;

  useEffect(() => {
    sdk?.field?.onValueChanged((value?: DamAsset[]) => {
      setDamAssets(value);
    });
  }, []);

  const onInputChange = debounce((image: DamAsset, description: string) => {
    image.description = description;
    onDescriptionChange(damAssets);
  }, DEBOUNCE_TIME);

  const isAssetTypeImage = sdk.parameters.instance.type === APP_PARAMETER.CONTENT_IMAGE;

  return (
    <Box style={{ margin: '15px 10px 0' }}>
      <FormControl.Label>Media Description</FormControl.Label>

      {damAssets?.map((media, index, array) => {
        if (array.length - 1 === index) {
          setTimeout(() => {
            sdk.window.startAutoResizer();
          }, FILED_RESIZE_DELAY);
        }

        const renderSrcImage = isAssetTypeImage ? media?.url : media.thumbnail;

        return (
          <FormControl isRequired key={media?.id || media?.url || index} marginBottom="spacingXs">
            <TextInput.Group spacing="spacingS">
              <Asset style={{ width: '75px' }} src={renderSrcImage} type="image" />
              <Box>
                {isAssetTypeImage && (
                  <TextInput
                    onChange={(e) => onInputChange(media, e.target.value)}
                    defaultValue={media?.description}
                    name="image"
                    maxLength={60}
                  />
                )}
                <Box display="flex" align>
                  <Note variant="neutral" style={{ padding: tokens.spacingXs }}>
                    {media?.uuid}
                  </Note>
                  <CopyButton value={media?.uuid} />
                </Box>
              </Box>
            </TextInput.Group>
          </FormControl>
        );
      })}
    </Box>
  );
};

export default MediaDescription;
