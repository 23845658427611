import { render } from 'react-dom';
import { Asset, DialogAppSDK, FieldAppSDK, init } from '@contentful/app-sdk';
import { setup } from '@contentful/dam-app-base';
import { Flex } from '@contentful/f36-components';
import MediaDescription from './Components/MediaDescription/MediaDescription';
import { ASSET_META_URL, ASSET_TYPE, BF_EMBED_URL, CTA, MIME_TYPES } from './Utils/constants';
import { DocumentProps, ImageProps, VideoProps } from './Utils/types';
import { formatAssetMetaData, resolveNullContentfulField } from './Utils/utils';
import logo from './Resources/images/knauf-logo.svg';

const App = ({ instanceParams }) => {
  const makeThumbnail = (attachment): [string, string] => {
    let url,
      alt = '';
    if (attachment) {
      const thumbnail = attachment?.type === ASSET_TYPE.IMAGE ? attachment.url : attachment.thumbnail;
      url = typeof thumbnail === 'string' ? thumbnail : undefined;
      alt = attachment.title;
    }

    return [url, alt];
  };

  const renderDialog = (sdk: DialogAppSDK) => {
    const config = sdk.parameters.invocation;
    const container = document.createElement('div');
    const iframe = document.createElement('iframe');
    const searchParams = new URLSearchParams({
      viewmode: 'search',
      mode: config['mode'],
      assettype: config['type'] || '',
    });
    const mimeTypes = config['type'] && MIME_TYPES[config['type']];

    // Based on MIME_TYPES const, it will apply the mime type query parameters for filtering assets
    if (mimeTypes) {
      mimeTypes.forEach((mimeType) => {
        searchParams.append('mimetype', `*${mimeType}`);
      });
    } else {
      searchParams.delete('mimetype');
    }
    const damUrl = `${BF_EMBED_URL}?${searchParams.toString()}`;
    iframe.id = 'knauf-embed';
    iframe.className = 'iframe-container';
    iframe.src = damUrl;
    iframe.width = '100%';
    iframe.height = '650';
    iframe.sandbox.add('allow-popups');
    iframe.sandbox.add('allow-scripts');
    iframe.sandbox.add('allow-same-origin');
    iframe.sandbox.add('allow-forms');

    iframe.style.border = 'none';
    container.appendChild(iframe);

    document.body.appendChild(container);

    window.addEventListener('message', async (e) => {
      const { origin } = new URL(BF_EMBED_URL);
      if (e.origin !== origin) {
        return;
      }

      if (e.source !== iframe.contentWindow) {
        return;
      }
      if (!e.data) return;
      const { data } = JSON.parse(e.data);
      const damAssetData = await Promise.all(fetchAssetMetaData(data)).catch((error) => console.error(error));
      sdk.close(damAssetData);
    });
    sdk.window.updateHeight(Number(iframe.height));
  };

  const fetchAssetMetaData = (assetList): Promise<ImageProps | VideoProps | DocumentProps>[] => {
    return assetList.map(async (asset) => {
      const resp = await fetch(`${asset.url}${ASSET_META_URL}`);
      return resp
        .json()
        .then(formatAssetMetaData)
        .catch((e) => console.error(e));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const openDialog = async (sdk: FieldAppSDK, _currentValue, _config) => {
    const appConfig = sdk.parameters.installation?.instanceParams
      ? sdk.parameters.installation.instanceParams
      : sdk.parameters.instance;
    const result = await sdk.dialogs.openCurrentApp({
      position: 'center',
      title: CTA,
      shouldCloseOnOverlayClick: true,
      shouldCloseOnEscapePress: true,
      parameters: { ...appConfig },
      width: 'fullWidth',
      allowHeightOverflow: false,
    });

    if (!Array.isArray(result)) {
      return [];
    }

    return result;
  };

  const isDisabled = (currentValue: unknown[]): boolean => {
    const maxFiles = instanceParams.maxFiles || {};

    return Array.isArray(currentValue) && currentValue.length >= maxFiles;
  };

  return (
    <>
      {setup({
        cta: CTA,
        name: 'Knauf',
        logo,
        color: '#40D1F5',
        description:
          'The DAM app is a widget that allows editors to select media from their Knauf account. Select a file on DAM and designate the assets that you want your entry to reference.',
        parameterDefinitions: [],
        validateParameters: () => null,
        makeThumbnail,
        renderDialog,
        openDialog,
        isDisabled,
      })}
    </>
  );
};

init((sdk: FieldAppSDK) => {
  const root = document.getElementById('dam-root');

  resolveNullContentfulField(sdk);

  const updateFieldContent = async (value: Asset) => {
    await sdk.field.setValue(value);
  };

  render(
    <Flex flexDirection="column">
      <App instanceParams={sdk.parameters.instance} />
      <MediaDescription onDescriptionChange={updateFieldContent} sdk={sdk} />
    </Flex>,
    root,
  );
});
