export const BF_EMBED_URL = process.env.REACT_APP_DAM_URL;
export const CTA = 'Select an asset on Knauf DAM';
export const IMAGE_PATH = 'is/image/';
export const CONTENT_PATH = 'is/content/';
export const ASSET_META_URL = '/jcr:content/metadata.json';
export const FALLBACK_THUMBNAIL = {
  DOCUMENT: 'https://s7g10.scene7.com/is/image/knaufstage/thumbnail-document',
  VIDEO: 'https://s7g10.scene7.com/is/image/knaufstage/thumbnail-video',
};
export enum ASSET_TYPE {
  IMAGE = 'Image',
  VIDEO = 'Video',
}

export enum APP_PARAMETER {
  CONTENT_ICON = 'icons',
  CONTENT_IMAGE = 'images',
  CONTENT_MULTIMEDIA = 'multimedia',
  CONTENT_DOCUMENT = 'documents',
}

export const MIME_TYPES = {
  icons: ['gif', 'jpg', 'jpeg', 'png', 'svg+xml', 'tif', 'tiff'],
  images: ['bmp', 'gif', 'jpg', 'jpeg', 'png', 'psd', 'tif', 'tiff'],
  multimedia: [
    'avi', // video/x-msvideo
    'm4v', // video/x-m4v
    'mov', // video/quicktime
    'mp4', // video/mp4
    'mpeg', // video/mpeg
    'vob', // video/x-ms-vob
    'webm', // video/webm
    'wmv', // video/x-ms-wmv
  ],
  documents: ['pdf'],
};

export enum ASSET_META {
  ID = 'dam:scene7ID',
  UUID = 'dam:uuid',
  DOMAIN = 'dam:scene7Domain',
  FILE = 'dam:scene7File',
  TYPE = 'dam:scene7Type',
  TITLE = 'dc:title',
  DESCRIPTION = 'dc:description',
  FORMAT = 'dc:format',
  SIZE = 'dam:size',
  IMAGE_LENGTH = 'tiff:ImageLength',
  IMAGE_WIDTH = 'tiff:ImageWidth',
  DOC_THUMBNAIL = 'dam:scene7IpsImageUrl',
}
